import React from "react"

const DomainCards = () => (
  <section className="c-domain-cards">
    <section className="wrapper">
      <section className="c-domain-cards__container">
        <h5 className="c-domain-cards__title">
          Y además tienes otras opciones...
        </h5>
        <div className="c-domain-cards__options">
            <div className="c-domain-cards__card">
              <div className="c-domain-cards__card-body">
                <img src="https://via.placeholder.com/150" alt="" />
                <h5>Transferir un dominio desde otro proveedor</h5>
                <p>Si ya tienes dominio, puedes trasferirlo a Rackmarkt en un solo paso. Así tendras control total para gestionarlo.</p>
              </div>
              <div className="c-domain-cards__card-footer">
                <p>Quiero transferirlo</p>
              </div>
            </div>
            <div className="c-domain-cards__card">
              <div className="c-domain-cards__card-body">
                <img src="https://via.placeholder.com/150" alt="" />
                <h5>Crear cuentas de correo con tu nombre de dominio</h5>
                <p>¿Quieres direcciones de email personalizadas con tu dominio? Registralo al contratar tu Hosting Correo.</p>
              </div>
              <div className="c-domain-cards__card-footer">
                <p>Ver hosting correo</p>
              </div>
            </div>
            <div className="c-domain-cards__card">
              <div className="c-domain-cards__card-body">
                <img src="https://via.placeholder.com/150" alt="" />
                <h5>Registrar un dominio con un plan de hosting</h5>
                <p>Podrás registrar directamente tu nuevo dominio durante el proceso de contratación de cualquier plan de hosting.</p>
              </div>
              <div className="c-domain-cards__card-footer">
                <p>Dominio y plan</p>
              </div>
            </div>
        </div>
      </section>
    </section>
  </section>
)

export default DomainCards
