import React from "react"

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import DomainComponent from "../components/domain"

const DominiosPage = () => (
  <Layout>
    <SEO title="Dominios" />
    <DomainComponent />
  </Layout>
)

export default DominiosPage
