import React from "react"

const DomainSearch = () => (
  <section className="c-domain-search">
    <section className="wrapper">
      <section className="c-domain-search__container">
        <h5 className="c-domain-search__title">Registra tu dominio</h5>
        <p className="c-domain-search__description">Ha llegado el momento de registrar el dominio perfecto para tu web y tu correo. ¡Compruebe si está libre!</p>

        <div className="c-domain-search__searcher">
          <input className="c-domain-search__input" type="text" name="domain" id="domain-select" placeholder="Introduce el dominio que quieres buscar" aria-label="Introduce el dominio que quieres buscar"/>
          <select className="c-domain-search__select" name="domain" id="domain-select">
            <option value="x" label=".es">.es</option>
            <option value="x" label=".com">.com</option>
            <option value="x" label=".net">.net</option>
          </select>
          <button className="btn-primary c-domain-search__btn-searcher">Buscar</button>
        </div>

        <div className="c-domain-search__searcher-mobile">
          <input className="c-domain-search__input" type="text" name="domain" id="domain-select" placeholder="Introduce el dominio que quieres buscar" aria-label="Introduce el dominio que quieres buscar"/>
          <select className="c-domain-search__select" name="domain" id="domain-select">
            <option value="x" label=".es">.es</option>
            <option value="x" label=".com">.com</option>
            <option value="x" label=".net">.net</option>
          </select>
          <button className="btn-primary c-domain-search__btn-searcher">Buscar</button>
        </div>
        
      </section>
    </section>
  </section>
)

export default DomainSearch
