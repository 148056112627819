import React from "react"

const DomainBanner = () => (
  <section className="c-banner-domain">
    <section className="wrapper">
      <section className="c-banner-domain__container">
        <div className="c-banner-domain__info">
          <h5 className="c-banner-domain__title">Tu dominio</h5>
          <p className="c-banner-domain__description">Es la entrada a tus servicios</p>
        </div>
      </section>
    </section>
  </section>
)

export default DomainBanner
