import React from "react"

import DomainBanner from "./domain-banner"
import DomainSearch from "./domain-search"
import DomainCards from "./domain-cards"
import Call from "../call"
import AccordionPanel from '../accordion'
import logo from "../../images/logos/logo_round_white.png"

const panels = [
  {
    label: 'Seriously, Don\'t Use Icon Fonts',
    content: 'Icons are everywhere. These "little miracle workers" (as John Hicks described them) help us reinforce meaning in the interfaces we design and build. Their popularity in web design has never been greater; the conciseness and versatility of pictograms in particular make them a lovely fit for displays large and small. But icons on the web have had their fair share of challenges.',
  },
  {
    label: 'Screen Readers Actually Read That Stuff',
    content: 'Most assistive devices will read aloud text inserted via CSS, and many of the Unicode characters icon fonts depend on are no exception. Best-case scenario, your "favorite" icon gets read aloud as "black favorite star." Worse-case scenario, it\'s read as "unpronounceable" or skipped entirely.',
  },
  {
    label: 'They Fail Poorly and Often',
    content: 'When your icon font fails, the browser treats it like any other font and replaces it with a fallback. Best-case scenario, you\'ve chosen your fallback characters carefully and something weird-looking but communicative still loads. Worse-case scenario (and far more often), the user sees something completely incongruous, usually the dreaded "missing character" glyph.',
  },
  {
    label: 'They\'re a Nightmare if You\'re Dyslexic',
    content: 'Many dyslexic people find it helpful to swap out a website\'s typeface for something like OpenDyslexic. But icon fonts get replaced as well, which makes for a frustratingly broken experience.',
  },
  {
    label: 'There\'s Already a Better Way',
    content: 'SVG is awesome for icons! It\'s a vector image format with optional support for CSS, JavaScript, reusability, accessibility and a bunch more. It was made for this sort of thing.'
  },
];

const DomainComponent = () => (
  <>
    <DomainBanner />
    <DomainSearch />
    <section className="c-domain-body">
      <section className="wrapper">
        <section className="c-domain-body__container">
          <h1 className="c-domain-body__title">Dominios en buenas manos.</h1>

          <p className="c-domain-body__description">
            Es muy importante mantener nuestros activos digitales a buen
            recaudo, los dominios son la puerta de entrada a nuestro sitio. Es
            por ello que en Rackmarkt te ofrecemos los mejores precios y
            herramientas para gestionar tus dominios, tanto si ya los tenias
            adquiridos como si estas pensando en registrarlo. Disponemos de
            múltiples extensiones (genéricas, territoriales, especialidades,
            etc). Una gestión sencilla de los mismos, delegación de nameservers,
            creación de registros DNS y la posibilidad de{" "}
            <a className="link" href="https://rackmarkt.pixerama.es/hosting/">
              adquirir un paquete de hosting
            </a>{" "}
            asociado a tu dominio para sacarle el mejor partido. El registro se
            produce de manera automática una vez recibido el pago del mismo, tu
            dominio estará disponible de manera inmediata. Las transferencias
            tienen un tiempo de procesamiento debido a que tenemos que
            interactuar con el actual registrador para solicitarle su traspaso.
          </p>

          <p className="c-domain-body__description">
            Proteger nuestros nombres de marcas o negocios en múltiples
            extensiones nos garantiza una futura expansión, los dominios
            permiten que tu web sea encontrada, fácil de recordar y refuerzan tu
            presencia en la red.
          </p>
        </section>
      </section>
    </section>

    <DomainCards />

    <section className="c-domain-body">
      <section className="wrapper">
        <h2 className="c-domain-body__title">
          ¿QUÉ DEBO CONSIDERAR A LA HORA DE ELEGIR UN DOMINIO?
        </h2>
        <section
          className="c-domain-body__container row"
          style={{ justifyContent: "center" }}
        >
          <div className="c-domain-body__text col-xs-12 col-sm-6 col-md-8">
            <p className="c-domain-body__description">
              Es muy importante la elección de un dominio, este será el que nos
              identifique a nosotros o a nuestro negocio. Es importante plasmar
              de una manera clara y sin llevar a confusión la manera de
              escribirlo. Es por ello que recomendamos en primer lugar intentar
              conseguir una extensión genérica o territorial. La utilización de
              elementos de separación no es un buen recurso, las direcciones
              cuanto más cortas sean mejor intentando evitar el uso de
              artículos.
            </p>
          </div>

          <div className="c-domain-body__logo-container col-xs--12 col-sm-6 col-md-4">
            <img src={logo} alt="Logo Rackmarkt" />
          </div>
        </section>
      </section>
    </section>
    <AccordionPanel itemsData={panels} />
    <Call />
  </>
)

export default DomainComponent
